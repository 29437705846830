const type = 'Optimization';

// 测算发起
// 单个问题，多个参数
export const URL_OPTIMIZE_SINGLE_PROBLEM = `optimization/base_problem`; // TODO
// 多个问题，一个参数
export const URL_OPTIMIZE_MULTY_PROBLEMS = `optimization/base_parameter`;
// 一个问题集，一个参数
export const URL_OPTIMIZE_PROBLEM_SET = `optimization/base_problemSet`;

// 测算请求详情
// 针对单个问题，多个参数，获取优化结果
export const URL_SINGLE_OPTIMIZITION_DETAIL = `result/base_problem/`; // TODO
// 针对多个问题，一个参数，获取优化结果
export const URL_MULTY_OPTIMIZITION_DETAIL = `result/base_problemSet/`;

// 单个问题的优化结果详情(原始日志)
export const URL_OPTIMIZATION_LOG = `result/opt_result_log`;

const problemSet = (builder) => ({
  getOptimizations: builder.query({
    query: (data) => ({
      url: '/optimization/query',
      method: 'POST',
      data: {
        project_name: `default`,
        ...data,
      },
    }),
    providesTags: (result) =>
      result
        ? [
            ...result?.data.map(({ id }) => ({ type, id })),
            { type, id: 'LIST' },
          ]
        : [{ type, id: 'LIST' }],
  }),
  cancelOptimizations: builder.mutation({
    query: (id) => ({
      url: '/result/cancel',
      method: 'POST',
      data: {
        requestId: id,
      },
    }),
    invalidatesTags: (result, error, id) => [
      { type, id },
      { type, id: 'LIST' },
    ],
  }),

  // 基于单个问题发起测算
  addOptimizationByProblem: builder.mutation({
    query: (formData) => {
      formData.append('project_name', `default`);
      return {
        url: '/optimization/base_problem',
        method: 'POST',
        data: formData,
      }
    },
    invalidatesTags: [{ type, id: 'LIST' }],
  }),
  // 基于多个问题发起测算
  addOptimizationByProblems: builder.mutation({
    query: (formData) => {
      formData.append('project_name', `default`);
      return {
        url: '/optimization/base_parameter',
        method: 'POST',
        data: formData,
      }
    },
    invalidatesTags: [{ type, id: 'LIST' }],
  }),
  // 基于问题集发起测算
  addOptimizationByProblemSet: builder.mutation({
    query: (formData) => {
      formData.append('project_name', `default`);
      return {
        url: '/optimization/base_problemSet',
        method: 'POST',
        data: formData,
      };
    },
    invalidatesTags: [{ type, id: 'LIST' }],
  }),

  // 针对单个问题，多个参数，获取优化结果
  getOptimizationByProblem: builder.query({
    query: (id) => ({
      url: `/result/base_problem/${id}`,
      method: 'GET',
    }),
    providesTags: (result, error, id) => [{ type, id }],
  }),
  // 针对多个问题，一个参数，获取优化结果
  getOptimizationByProblems: builder.query({
    query: (id) => ({
      url: `/result/base_problemSet/${id}`,
      method: 'GET',
    }),
    providesTags: (result, error, id) => [{ type, id }],
  }),
});

export default problemSet;
