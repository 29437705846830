import axios from 'axios';

import { authActions } from 'store/authSlice';

import { defaultLocale } from '../languages';

const axiosBaseQuery =
  ({ baseURL } = { baseURL: '' }) =>
  async (axiosOptions = {}, { dispatch, getState }) => {
    try {
      const { headers = {}, ...restOptions } = axiosOptions;
      headers['Accept-Language'] = defaultLocale;
      const response = await axios({
        baseURL,
        withCredentials: true,
        headers,
        ...restOptions,
      });
      if (response?.data?.access_token) {
        // login api
        return { data: response?.data };
      }
      if (response?.data?.code !== 0) {
        // Backend service internal error
        return {
          error: {
            status: response?.data?.code,
            message: response?.data?.message,
          },
        };
      }
      return { data: response?.data?.data };
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx

        if (error.response.status === 401) {
          // Not logged in or expired
          dispatch(authActions.redirectLogin());
        }
        return {
          error: {
            status: error.response.status,
            message: error.response?.data?.message ?? 'Internal Server Error',
            response: error.response.data,
          },
        };
      }
      if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        return {
          error: {
            status: 500,
            message: 'Internal Server Error!',
            request: error.request,
          },
        };
      }
      return { error };
    }
  };
export default axiosBaseQuery;
